import { URL_PROD, URL_DEV } from './env';

let urlBase = URL_DEV === 'development' ? 'http://127.0.0.1:8000/api/' : URL_PROD;
// let urlBase = URL_DEV === 'development' ? 'https://b9d9-191-95-149-54.ngrok.io/api/' : URL_PROD;

// Login
export const urlValidateLogin = `${urlBase}auth/login`;

// Roles
export const urlGetRoles = `${urlBase}roles/consult-role`;

// Dashboard
export const urlGetDashboard = `${urlBase}dashboard/consult-total`;
export const urlGetDashCashRegister = `${urlBase}dashboard/consult-cashregister`;
export const urlGetDashCashRegisterByCode = `${urlBase}dashboard/consult-cashregisterDetail`;
export const urlDashCashRegisterTotals = `${urlBase}dashboard/consult-totals`

// Headquarters
export const urlGetHeadquarters = `${urlBase}headquarters/consult-headquarters`;
export const urlPostHeadquarters = `${urlBase}headquarters/create-headquarters`;
export const urlEditHeadquarters = `${urlBase}headquarters/edit-headquarters`;

// Team
export const urlGetTeam = `${urlBase}users/consult-user`;
export const urlPostTeam = `${urlBase}users/create-user`;

// Inventory
export const urlGetInventory = `${urlBase}products/consult-product`;
export const urlPostInventory = `${urlBase}products/create-product`;
export const urlEditInventory = `${urlBase}products/edit-product`;

// Thirds
export const urlGetThirds = `${urlBase}thirds/consult-third`;
export const urlPostThirds = `${urlBase}thirds/create-third`;

// CashRegister
export const urlGetCashRegister = `${urlBase}cashRegister/consult-cashregister`;
export const urlPostCashRegister = `${urlBase}cashRegister/create-cashregister`;
export const urlGetInventoryById = `${urlBase}cashRegister/consult-product`;
export const urlGetCashRegisterDetailByCode = `${urlBase}cashRegister/consult-cashregisterdetail`;