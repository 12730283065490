import { urlValidateLogin } from '../../../../constants/backUrls';
import { handleResponse } from '../../../../helpers/commonFunctions';

const authHeader = {
	'Accept': 'application/json',
	'Content-type': 'application/json',
}

export const validateLogin = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader,
		body: JSON.stringify(data),
	};

	return fetch(urlValidateLogin, requestOptions).then(handleResponse);
};
