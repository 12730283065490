import React, { Fragment } from 'react';
import Login from '../../components/Auth/Login';
import { loginRoute } from '../../routers/routes';

const LoginContainer = () => {
    return (
        <Fragment>
            <Login titlePage="Ingresar" slug={loginRoute} />
        </Fragment>
    );
}


export default LoginContainer;