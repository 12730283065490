import * as api from './../constants/backUrls';
import Loading from '../components/Utils/Loading';

const authHeader = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
}

// API´S Headquarters (Sedes)
export const getRoles = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader,
	};

	return fetch(api.urlGetRoles, requestOptions).then((response) => response.json());
};

// API´S Headquarters (Sedes)
export const getHeadquarters = () => {
	<Loading />

	const requestOptions = {
		method: 'GET',
		headers: authHeader,
	};

	return fetch(api.urlGetHeadquarters, requestOptions).then((response) => response.json());
};

// API´S Users (Usuarios)
export const getUsers = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader,
	};

	return fetch(api.urlGetTeam, requestOptions).then((response) => response.json());
};

// API´S Products (Productos)
export const getInventories = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader,
	};

	return fetch(api.urlGetInventory, requestOptions).then((response) => response.json());
};

// API´S Thirds (Terceros)
export const getThirds = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader,
	};

	return fetch(api.urlGetThirds, requestOptions).then((response) => response.json());
};

// API´S CashRegister (Ventas)
export const getCashRegister = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader,
	};

	return fetch(api.urlGetCashRegister, requestOptions).then((response) => response.json());
};

export const getCashRegisterDetailByCode = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader,
		body: JSON.stringify(data),
	};

	return fetch(api.urlGetCashRegisterDetailByCode, requestOptions).then((response) => response.json());
};