import { urlGetDashboard, urlGetDashCashRegister, urlGetDashCashRegisterByCode, urlDashCashRegisterTotals } from '../../../../constants/backUrls';
import { handleResponse } from '../../../../helpers/commonFunctions';

const authHeader = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
}

// Get
export const getDashboard = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader,
    };

    return fetch(urlGetDashboard, requestOptions).then(handleResponse);
};

export const getDashCashRegister = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader,
    };

    return fetch(urlGetDashCashRegister, requestOptions).then(handleResponse);
};

// Post
export const getDashCashRegisterByCode = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader,
        body: JSON.stringify(data),
    };

    return fetch(urlGetDashCashRegisterByCode, requestOptions).then((response) => response.json());
};

export const getDashCashRegisterTotals = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader,
        body: JSON.stringify(data),
    };

    return fetch(urlDashCashRegisterTotals, requestOptions).then((response) => response.json());
};