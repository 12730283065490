import React from 'react';
import { Link, NavLink } from "react-router-dom";
import * as routes from '../../../routers/routes';
import { RemoveValueFromLocalStorage, getValueFromLocalStorage } from '../../../helpers/commonFunctions';

export default function Sidebar() {

    const handleClick = () => {
        RemoveValueFromLocalStorage('token');
        window.location.href = "/";
    }

    const firstName = getValueFromLocalStorage('firstName');
    const headquartersName = getValueFromLocalStorage('headquartersName');
    return (        
        <aside className="suite__sidebar">
            <div className="suite__sidebar-navbar">
                <div className="logo mt-4">
                    <img src={require('../../../img/logo-servicent.png')} alt="Logo Servicent" className="logo-servicent" />
                    <div className='divider-sidebar'></div>
                </div>

                <strong className='text-center'>✌ ¡Hola { firstName }! 🤩</strong>
                <h6 className='text-center'>Sede: { headquartersName }</h6>
                <div className="sidebar-menu">
                    <NavLink to={routes.dashboardRoute}>
                        <li className='menu-item'>
                            <div className='content-icon'>
                                <i className="fa-solid fa-chart-line"></i>
                            </div>
                            Dashboard
            </li>
                    </NavLink>
                    <NavLink to={routes.headquartersRoute}>
                        <li className='menu-item'>
                            <div className='content-icon'>
                                <i className="fa-solid fa-building-flag"></i>
                            </div>
                            Nuestras sedes
            </li>
                    </NavLink>
                    <NavLink to={routes.teamRoute}>
                        <li className="menu-item">
                            <div className='content-icon'>
                                <i className="fa-solid fa-people-group"></i>
                            </div>
                            Mi equipo
            </li>
                    </NavLink>
                    <NavLink to={routes.inventoryRoute}>
                        <li className='menu-item'>
                            <div className='content-icon'>
                                <i className="fa-solid fa-store"></i>
                            </div>
                            Inventario
            </li>
                    </NavLink>
                    <NavLink to={routes.thirdsRoute}>
                        <li className='menu-item'>
                            <div className='content-icon'>
                                <i className="fa-solid fa-truck-fast"></i>
                            </div>
                            Terceros
            </li>
                    </NavLink>
                    <NavLink to={routes.cashRegisterRoute}>
                        <li className='menu-item'>
                            <div className='content-icon'>
                                <i className="fa-solid fa-tags"></i>
                            </div>
                            Ventas
            </li>
                    </NavLink>
                </div>

                <div className="logout">
                    <li>
                        <a onClick={handleClick}>
                            {/* <Link to="/logout"> */}
                            {/* <i className="fas fa-sign-out-alt"></i> */}
                            <i className="fa-solid fa-power-off"></i>
                            Cerrar sesión
            {/* </Link> */}
                        </a>
                    </li>
                </div>
            </div>
        </aside>
    )
}