import React from 'react';
import {
  BrowserRouter as Router,
  Routes, // instead of "Switch"
  Route,
  // Link, // instead of "Redirect"
  Navigate
} from 'react-router-dom';
import * as routes from './routes';
import Loadable from '@loadable/component';
import Loading from '../components/Utils/Loading';
import jwt_decode from 'jwt-decode';

// import AuthRouter from './AuthRouter';
import LoginContainer from '../containers/Auth/LoginContainer';

// Dashboard
import DashboardContainer from '../containers/Dashboard/DashboardContainer';

// Headquarters
import HeadquartersContainer from '../containers/Headquarters/HeadquartersContainer';

// Team
import TeamContainer from '../containers/Team/TeamContainer';

// Inventory
import InventoryContainer from '../containers/Inventory/InventoryContainer';

// Thirds
import ThirdsContainer from '../containers/Thirds/ThirdsContainer';

// CashRegister
import CashRegisterContainer from '../containers/CashRegister/CashRegisterContainer';

// Dashboard
// const DashboardContainer = Loadable(() => import('../containers/Dashboard/DashboardContainer'), {
//   fallback: <Loading />,
// })
const DashCashRegisterDetailContainer = Loadable(() => import('../containers/Dashboard/DashCashRegisterDetailContainer'), {
    fallback: <Loading />,
})

// Headquarters
// const HeadquartersContainer = Loadable(() => import('../containers/Headquarters/HeadquartersContainer'), {
//   fallback: <Loading />,
// })
const CreateHeadquartersContainer = Loadable(() => import('../containers/Headquarters/CreateHeadquartersContainer'), {
  fallback: <Loading />,
})
const EditHeadquartersContainer = Loadable(() => import('../containers/Headquarters/EditHeadquartersContainer'), {
  fallback: <Loading />,
})

// Team
// const TeamContainer = Loadable(() => import('../containers/Team/TeamContainer'), {
//   fallback: <Loading />,
// })
const CreateTeamContainer = Loadable(() => import('../containers/Team/CreateTeamContainer'), {
  fallback: <Loading />,
})

// Inventory
// const InventoryContainer = Loadable(() => import('../containers/Inventory/InventoryContainer'), {
//   fallback: <Loading />,
// })
const CreateInventoryContainer = Loadable(() => import('../containers/Inventory/CreateInventoryContainer'), {
  fallback: <Loading />,
})
const EditInventoryContainer = Loadable(() => import('../containers/Inventory/EditInventoryContainer'), {
  fallback: <Loading />,
})

// Thirds
// const ThirdsContainer = Loadable(() => import('../containers/Thirds/ThirdsContainer'), {
//   fallback: <Loading />,
// })
const CreateThirdsContainer = Loadable(() => import('../containers/Thirds/CreateThirdsContainer'), {
  fallback: <Loading />,
})

// CashRegister
// const CashRegisterContainer = Loadable(() => import('../containers/CashRegister/CashRegisterContainer'), {
//   fallback: <Loading />,
// })
const CreateCashRegisterContainer = Loadable(() => import('../containers/CashRegister/CreateCashRegisterContainer'), {
    fallback: <Loading />,
})
const CashRegisterDetailContainer = Loadable(() => import('../containers/CashRegister/CashRegisterDetailContainer'), {
    fallback: <Loading />,
})

const router = ({ authSesion }) => {
    if (authSesion) {
        const result = jwt_decode(authSesion);

        const dateNow = new Date();
        const dd = result.exp < dateNow.getTime();
        console.log(dd);
    }
	return (
    <Router>
      <>
        <Routes>
          {!authSesion ? 
            (
              <>
                <Route exact path={routes.loginRoute} element={<LoginContainer />}>
                </Route>
                <Route path={'/*'} element={<Navigate replace to="/ingresar" />}>
                </Route>
              </>
            ) : (
              <>
                {/* Dashboard */}
                <Route exact path={'/'} element={<Navigate replace to="/dashboard" />}>
                </Route>
                <Route exact path={routes.loginRoute} element={<Navigate replace to="/dashboard" />}>
                </Route>
                <Route path={routes.dashboardRoute} element={<DashboardContainer />}>
                </Route>
                <Route path={`${routes.dashCashRegisterDetailRoute}/:codigo`} element={<DashCashRegisterDetailContainer />}>
                </Route>  

                {/* Headquarters */}
                <Route path={routes.headquartersRoute} element={<HeadquartersContainer />}>
                </Route>
                <Route path={routes.createHeadquartersRoute} element={<CreateHeadquartersContainer />}>
                </Route>
                <Route path={`${routes.editHeadquartersRoute}/:id`} element={<EditHeadquartersContainer />}>
                </Route>                

                {/* Team */}
                <Route path={routes.teamRoute} element={<TeamContainer />}>
                </Route>
                <Route path={routes.createTeamRoute} element={<CreateTeamContainer />}>
                </Route>

                {/* Inventory */}
                <Route path={routes.inventoryRoute} element={<InventoryContainer />}>
                </Route>
                <Route path={routes.createInventoryRoute} element={<CreateInventoryContainer />}>
                </Route>
                <Route path={`${routes.editInventoryRoute}/:id`} element={<EditInventoryContainer />}>
                </Route> 

                {/* Thirds */}
                <Route path={routes.thirdsRoute} element={<ThirdsContainer />}>
                </Route>
                <Route path={routes.createThirdsRoute} element={<CreateThirdsContainer />}>
                </Route>

                {/* CashRegister */}
                <Route path={routes.cashRegisterRoute} element={<CashRegisterContainer />}>
                </Route>
                <Route path={routes.createCashRegisterRoute} element={<CreateCashRegisterContainer />}>
                </Route>
                <Route path={`${routes.cashRegisterDetailRoute}/:codigo`} element={<CashRegisterDetailContainer />}>
                </Route>                
              </>
            )
          }
        </Routes>
      </>
    </Router>
  );
};

export default router;