import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { createCashRegisterRoute, cashRegisterDetailRoute } from '../../../routers/routes';
import { getCashRegister } from '../../../services/App';
import ComponentWithFetchingData from '../../Utils/ComponentWithFetchingData';
import { numberFormat } from '../../../helpers/numberFormats'
import ComponentDetail from './Forms/Detail';
import { getValueFromLocalStorage, setValueToLocalStorage } from '../../../helpers/commonFunctions'

const MainTable = ({ data }) => {
    const userId = getValueFromLocalStorage('userId');
    const role = getValueFromLocalStorage('role');
    let result = [];
    if (role == 1) {
        result = data;
    }
    else {
        result = data.filter(e => e.user_informations_id == userId);
    }
    return (
        <div className='admin-table'>
            
            <table role="table" className='table'>
                <thead>
                    <tr role="row" className="table-row-head">
                        <th className="first-head-item">Codigo</th>
                        <th className="head-item">Fecha</th>
                        <th className="head-item">Total</th>
                        <th className="last-head-item"></th>
                    </tr>
                </thead>
                <tbody>

                    {result.map((cashRegister, index) => (
                        <tr key={`cashRegister-${cashRegister.id}`} role="row" className="table-row-body">
                            <td role="gridcell" className="first-body-item">
                                <div className="info-column">
                                    <span className="table-avatar">
                                        <img src="https://media.istockphoto.com/vectors/portrait-of-an-androgynous-young-girl-vector-id1264140678?k=20&m=1264140678&s=612x612&w=0&h=B244GvbvyrLWIA5y2KYfe4h6-CO3bimKuEP9vamd6M8=" className="avatar-img" />
                                    </span>
                                    <Link to={`${cashRegisterDetailRoute}/${cashRegister.codigo}`} style={{ textDecoration: 'none' }}>
                                        <div className="info" style={{cursor:'pointer'}}>
                                            <p className="text-sup">{cashRegister.codigo}</p>
                                            <p className="text-inf">{cashRegister.fullName}</p>
                                        </div>
                                    </Link>
                                </div>
                            </td>
                            <td role="gridcell" className="body-item">
                                <div className='info'>
                                    <p className="text-sup">{cashRegister.date}</p>
                                    <p className="text-inf">{cashRegister.headquartersName}</p>
                                </div>
                            </td>
                            <td role="gridcell" className="body-item">
                                <div className="info-icon">
                                    <i className="fa-solid fa-dollar"></i>
                                    <p>{numberFormat(cashRegister.total)}</p>
                                </div>
                            </td>
                            <td role="gridcell" className="body-item">
                                <button type="button" className="more-button">
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </button>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>
        </div>
    )
}

const CashRegister = () => {
    return (
        <section className='admin__table admin__cashregister'>
            <div className='heading'>
                <h2>Ventas</h2>
                <div className='functions'>
                    <input placeholder="Buscar" />
                    <Link to={createCashRegisterRoute}>
                        <button>Nuevo</button>
                    </Link>
                </div>
            </div>
            <ComponentWithFetchingData request={getCashRegister()} render={(data) => <MainTable data={data} />} />
        </section>
    )
}

export default CashRegister;
