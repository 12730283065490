export const handleResponse = (response) => {
	return response.json().then((data) => {
		if (!response.ok) {
			console.log('!response.ok', response.ok);
			if (response.status === 401) {
			}
			let error = (data && (data.error || data.message));
			if (response.status === 404) {
				error = 'NOT_FOUND';
			}
			return Promise.reject(data.error_type ? data : error);
		}
		console.log('response.ok', response.ok);
		return data;
	});
};

export const getValueFromLocalStorage = (key) => typeof window !== 'undefined' && localStorage.getItem(key);

export const setValueToLocalStorage = (key, value) => typeof window !== 'undefined' && localStorage.setItem(key, value);

export const RemoveValueFromLocalStorage = (key) => typeof window !== 'undefined' && localStorage.removeItem(key);

export const setVauleFromSessionStorage = (key, value) => typeof window !== 'undefined' && sessionStorage.setItem(key, value);

export const getValueFromSessionStorage = (key) => typeof window !== 'undefined' && sessionStorage.getItem(key);

export const RemoveValueFromSessionStorage = (key) => typeof window !== 'undefined' && sessionStorage.removeItem(key);