import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { createCashRegisterRoute, dashCashRegisterDetailRoute } from '../../../routers/routes';
import { getUsers } from '../../../services/App';
import { getDashboard, getDashCashRegister, getDashCashRegisterTotals } from './Services/ServicesDashboard';
import ComponentWithFetchingData from '../../Utils/ComponentWithFetchingData';
import { numberFormat } from '../../../helpers/numberFormats';
import { setValueToLocalStorage, getValueFromLocalStorage } from '../../../helpers/commonFunctions';



const Dashboard = () => {
  const [users, setUsers] = useState([])
  const [dashboard, setDashboard] = useState([])
  const [cashRegisters, setCashRegisters] = useState([])
  const [dataSearch, setDataSearch] = useState([])
  const [startDate, setStartDate] = useState([])
  const [endDate, setEndDate] = useState([])
  const [total, setTotal] = useState([])
  const [totalPayThird, setTotalPayThird] = useState([])
  const [totalDay, setTotalDay] = useState([])
  const [totalThird, setTotalThird] = useState([])
  const [totalOthers, setTotalOthers] = useState([])
  const [totalRefills, setTotalRefills] = useState([])
  const form = useRef(null)

  const selectUsers = () => {
    getUsers()
      .then((res) => {
          setUsers(res)
      })
      .catch((err) => {
          console.log('error', err);
      });
  }

  const selectCashRegister = () => {
    getDashCashRegister()
      .then((res) => {
          setCashRegisters(res)
      })
      .catch((err) => {
          console.log('error', err);
      });
  }

  const selectDashboard = async() => {
    let users = await getUsers();
    let cashRegisters = await getDashCashRegister();

    cashRegistersDashboard(users, cashRegisters, '', '');
  }

  const cashRegistersDashboard = async(users, cashRegisters, startDate, endDate) => {
    let usersId = [];
    users.forEach(function (item) {
      usersId.push(item.id);
    })

    let cash = [];
    let currentDate = formatDate(new Date());
    if (startDate !== '' && endDate !== ''){
      cash = cashRegisters.filter(e => e.date >= startDate && e.date <= endDate);
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (startDate !== '') {
      cash = cashRegisters.filter(e => e.date == startDate);
      setStartDate(startDate);
    } else {
      cash = cashRegisters.filter(e => e.date == currentDate);
      setStartDate(currentDate);
    }
    console.log(cash);

    let cashR = [];
    usersId.forEach(function (item) {
      let filter = cash.filter(e => e.user_informations_id == item);
      let codes = [];
      let user_informations_id = 0;
      let fullName = '';
      let others = 0;
      let refills = 0;
      let totalCash = 0;
      let totalValThird = 0;
      if (filter.length > 0) {
        filter.forEach(function (item) {
          let code = codes.find(e => e == item.codigo);
          if (code !== item.codigo) {
            codes.push(item.codigo);
          }
          user_informations_id = item.user_informations_id;
          fullName = item.fullName;
          if (item.productName === 'Recargas') {
            refills = refills + parseInt(item.total);
          } else {
            others = others + + parseInt(item.total);
          }         
          totalCash = totalCash + parseInt(item.total);
          totalValThird = totalValThird + parseInt(item.valueThird != null ? item.valueThird : 0);
        });
          let obj = {
            codes: codes,
            user_informations_id: user_informations_id,
            fullName: fullName,
            others: others,
            refills: refills,
            totalCash: totalCash,
            totalValThird: totalValThird,
          }
        cashR.push(obj);
      }
    })

    if (cashR.length == 0) {
      let obj = {
        fullName: 'No se encontraron datos.',
      }
      cashR.push(obj);
    }
    setDataSearch(cashR);
    console.log(dataSearch);

    let totalOther = 0;
    let totalRefill = 0;
    let totalCash = 0;
    let totalValThird = 0;
    cashR.forEach(function (item) {
      totalOther = totalOther + item.others;
      totalRefill = totalRefill + (item.refills != 0 ? item.refills : 0);
      totalCash = totalCash + item.totalCash;
      totalValThird = totalValThird + (item.totalValThird != 0 ? item.totalValThird : 0);
    })
    setTotalOthers(totalOther);
    setTotalRefills(totalRefill);
    setTotal(totalCash);
    setTotalPayThird(totalValThird);
  }

  useEffect(() => {
    selectUsers();
    selectCashRegister();
    selectDashboard();
  }, [])

  const getFormData = (dataForm) => {
      const object = {};
      dataForm.forEach(function (value, key) {
          object[key] = value;
      });
      const res = JSON.stringify(object);
      return res
  };

  const formatDate = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let formDate = year.toString() + '-' + (month.toString().length == 1 ? '0' + month.toString() : month.toString()) + '-' + (day.toString().length == 1 ? '0' + day.toString() : day.toString());

    return formDate;
  }

  const search = (e) => {
    e.preventDefault()

    const dataForm = new FormData(form.current)
    const data = getFormData(dataForm)
    const dataParse = JSON.parse(data);

    console.log(dataParse);

    cashRegistersDashboard(users, cashRegisters, dataParse.startDate, dataParse.endDate);
  };

  return (
    <form className='admin__table admin__dashboard row' ref={form}>
        <div className='heading'>
            <h2>Dashboard</h2>
        </div>

          <div className="col-md-3">
              <label htmlFor="startDate" className="form-label">Fecha de Inicio</label>
              <input type="date" className="form-control" id="startDate" name="startDate"/>
              <div className="invalid-feedback">
                  Please choose a username.
              </div>
          </div>
          <div className="col-md-3">
              <label htmlFor="endDate" className="form-label">Fecha de Fin</label>
              <input type="date" className="form-control" id="endDate" name="endDate"/>
              <div className="invalid-feedback">
                  Please choose a username.
              </div>
          </div>
          <div className="col-3">
              <div className='form-btn'>
                  <button className="btn btn-primary" onClick={search}>Buscar</button>
              </div>
          </div>


        <div className='admin-table'>
            <table role="table" className='table'>
                <thead>
                    <tr role="row" className="table-row-head">
                        <th className="head-item">Nombre</th>
                        <th className="head-item">Fecha</th>
                        <th className="head-item">Otros</th>
                        <th className="head-item">Recargas</th>
                        <th className="head-item">Total Ventas</th>
                        <th className="head-item">Pago a Terceros</th>
                        <th className="head-item">Ganancia</th>
                        <th className="last-head-item"></th>
                    </tr>
                </thead>
                <tbody>
                  {dataSearch.map((data, index) => (
                    data.fullName == 'No se encontraron datos.' ?
                      <tr key={`cashRegister-${data.user_informations_id}`} role="row" className="table-row-body">
                        <span>{data.fullName}</span>
                      </tr>
                    :
                    <tr id="11" key={`cashRegister-${data.user_informations_id}`} role="row" className="table-row-body">
                        <td role="gridcell" className="body-item">
                            <div className="info-column">
                                <span className="table-avatar">
                                    <img src="https://media.istockphoto.com/vectors/portrait-of-an-androgynous-young-girl-vector-id1264140678?k=20&m=1264140678&s=612x612&w=0&h=B244GvbvyrLWIA5y2KYfe4h6-CO3bimKuEP9vamd6M8=" className="avatar-img" />
                                </span>
                                <div className="info">
                                    <p className="text-inf">{data.fullName}</p>
                                </div>
                            </div>
                        </td>
                        <td role="gridcell" className="body-item">
                            <div className='info'>
                                <p className="text-sup">{endDate != '' ? startDate  + ' a ' + endDate : startDate}</p>
                            </div>
                        </td>
                        <td role="gridcell" className="body-item">
                            <div className="info-icon">
                                <i className="fa-solid fa-dollar"></i>
                                <p>{numberFormat(data.others)}</p>
                            </div>
                        </td>
                        <td role="gridcell" className="body-item">
                            <div className="info-icon">
                                <i className="fa-solid fa-dollar"></i>
                                <p>{numberFormat(data.refills)}</p>
                            </div>
                        </td>
                        <td role="gridcell" className="body-item">
                            <div className="info-icon">
                                <i className="fa-solid fa-dollar"></i>
                                <p>{numberFormat(data.totalCash)}</p>
                            </div>
                        </td>
                        <td role="gridcell" className="body-item">
                            <div className="info-icon">
                                <i className="fa-solid fa-dollar"></i>
                                <p>{numberFormat(data.totalValThird)}</p>
                            </div>
                        </td>
                        <td role="gridcell" className="body-item">
                            <div className="info-icon">
                                <i className="fa-solid fa-dollar"></i>
                                <p>{numberFormat(data.totalCash - data.totalValThird)}</p>
                            </div>
                        </td>
                        <td role="gridcell" className="body-item">
                            <Link to={`${dashCashRegisterDetailRoute}/${data.codes}`} style={{ textDecoration: 'none' }}>
                                <div className="info" style={{cursor:'pointer'}}>
                                    <p className="text-inf">Ver detalle</p>
                                </div>
                            </Link>
                        </td>
                    </tr>
                  ))
                }
                  <tr key={`cashRegister-${dataSearch.length > 0 ? dataSearch[0].user_informations_id : ''}`} role="row" className="table-row-body">
                      <td role="gridcell" className="body-item">
                          <div className='info'>

                          </div>
                      </td>
                      <td role="gridcell" className="body-item">
                          <div className='info'>
                              <p className="text-sup">TOTALES:</p>
                          </div>
                      </td>                      
                      <td role="gridcell" className="body-item">
                          <div className="info">
                              <p className="text-sup">{numberFormat(totalOthers)}</p>
                          </div>
                      </td>
                      <td role="gridcell" className="body-item">
                          <div className="info">
                              <p className="text-sup">{numberFormat(totalRefills)}</p>
                          </div>
                      </td>
                      <td role="gridcell" className="body-item">
                          <div className="info">
                              <p className="text-sup">{numberFormat(total)}</p>
                          </div>
                      </td>
                      <td role="gridcell" className="body-item">
                          <div className="info">
                              <p className="text-sup">{numberFormat(totalPayThird)}</p>
                          </div>
                      </td>
                      <td role="gridcell" className="body-item">
                          <div className="info">
                              <p className="text-sup">{numberFormat(total - totalPayThird)}</p>
                          </div>
                      </td>
                  </tr>
                </tbody>
            </table>
        </div>
    </form>
  )
}

export default Dashboard;
