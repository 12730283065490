import React from 'react';
import Router from './routers/router'
import { getValueFromLocalStorage, setValueToLocalStorage } from './helpers/commonFunctions'
import './App.css';

function App() {
  const data = {
    email: 'pruebap@prueba.com',
    password: '123456789'
  }
  setValueToLocalStorage('testUser', JSON.stringify(data));
  const authSesion = getValueFromLocalStorage('token');

  return (
    <div className="App">
      <Router authSesion={authSesion} />
    </div>
  );
}

export default App;
