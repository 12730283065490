import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { createCashRegisterRoute, cashRegisterRoute } from '../../../../routers/routes';
import { getCashRegister, getCashRegisterDetailByCode } from '../../../../services/App';
import ComponentWithFetchingData from '../../../Utils/ComponentWithFetchingData';
import { numberFormat } from '../../../../helpers/numberFormats'

const ComponentDetail = ({ data }) => {
    const [cashRegisterDetail, setCashRegisterDetail] = useState([])
    let { codigo } = useParams();
    const getcashRegister = data.find(e => e.codigo == codigo);

    const obj = {
        codigo: codigo
    }

    const selectCashRegisterDetail = () => {
        getCashRegisterDetailByCode(obj)
            .then((res) => {
                setCashRegisterDetail(res)
            })
            .catch((err) => {
                console.log('error', err);
            });
    }

    useEffect(() => {
        selectCashRegisterDetail();
    }, [])

    return (
        <div className='admin-table'>
            <table role="table" className='table'>
                <thead>
                    <tr role="row" className="table-row-head">
                        <th className="first-head-item">Codigo</th>
                        <th className="head-item">Fecha</th>
                        <th className="head-item">Producto/Servicio</th>
                        <th className="head-item">Precio</th>
                        <th className="head-item">Subtotal</th>
                        <th className="last-head-item"></th>
                    </tr>
                </thead>
                <tbody>

                    {cashRegisterDetail.map((cashRegister, index) => (
                        <tr key={`cashRegister-${cashRegister.id}`} role="row" className="table-row-body">
                            <td role="gridcell" className="first-body-item">
                                <div className="info-column">
                                    <span className="table-avatar">
                                        <img src="https://media.istockphoto.com/vectors/portrait-of-an-androgynous-young-girl-vector-id1264140678?k=20&m=1264140678&s=612x612&w=0&h=B244GvbvyrLWIA5y2KYfe4h6-CO3bimKuEP9vamd6M8=" className="avatar-img" />
                                    </span>
                                    <div className="info">
                                        <p className="text-sup">{codigo}</p>
                                        <p className="text-inf">{getcashRegister.fullName}</p>
                                    </div>
                                </div>
                            </td>
                            <td role="gridcell" className="body-item">
                                <div className='info'>
                                    <p className="text-sup">{getcashRegister.date}</p>
                                    <p className="text-inf">{getcashRegister.headquartersName}</p>
                                </div>
                            </td>
                            <td role="gridcell" className="body-item">
                                <div className='info'>
                                    <p className="text-sup">{cashRegister.productName}</p>
                                    <p className="text-inf">Cantidad: {cashRegister.quantity}</p>
                                </div>
                            </td>
                            <td role="gridcell" className="body-item">
                                <div className='info'>
                                    <p>{numberFormat(cashRegister.value)}</p>
                                </div>
                            </td>
                            <td role="gridcell" className="body-item">
                                <div className="info-icon">                                    
                                    <strong><p className="text-sup">{numberFormat(cashRegister.subtotal)}</p></strong>
                                </div>
                            </td>
                            <td role="gridcell" className="body-item">
                                <button type="button" className="more-button">
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </button>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>
        </div>
    )
}

const CashRegister = () => {
    return (
        <section className='admin__table admin__cashregister'>
            <div className='heading'>
                <h2>Detalle venta</h2>
                <div className='functions'>
                    <Link to={cashRegisterRoute}>
                        <button><i className="fa-solid fa-angle-left"></i> Volver</button>
                    </Link>
                </div>
            </div>
            <ComponentWithFetchingData request={getCashRegister()} render={(data) => <ComponentDetail data={data} />} />
        </section>
    )
}

export default CashRegister;
