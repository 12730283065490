import React, { Fragment } from 'react';
import AppLayout from '../../components/Layout/index';
import CashRegister from '../../components/Admin/CashRegister/CashRegister';
import { cashRegisterRoute } from '../../routers/routes';

const CashRegisterContainer = () => {
    return (
        <Fragment>
            <AppLayout body={<CashRegister />} titlePage="Ventas" slug={cashRegisterRoute} />
        </Fragment>
    );
}


export default CashRegisterContainer;