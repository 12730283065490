import React, { useState, useEffect, useRef } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom'
import { setValueToLocalStorage, getValueFromLocalStorage } from '../../../helpers/commonFunctions';
import { getUsers, getHeadquarters } from '../../../services/App';
import { validateLogin } from './Services/ServicesLogin';

const LoginForm = ({ loggingIn }) => {
    const [headquarters, setHeadquarters] = useState([])
    const form = useRef(null)

    // let navigate = useNavigate();
    const testUser = getValueFromLocalStorage('testUser');
    console.log('testUser', JSON.parse(testUser));

    const validateUser = (item1, item2) => {
        const user = JSON.parse(testUser);
        const email = user.email;
        const password = user.password;
        if (email === item1 && password === item2) {
            setValueToLocalStorage('token', true);
            // navigate("/dashboard", { replace: true });
            window.location.href="/dashboard"
        }
    }

    const selectHeadquarters = () => {
        getHeadquarters()
            .then((res) => {
                setHeadquarters(res)
            })
            .catch((err) => {
                console.log('error', err);
            });
    }

    useEffect(() => {
        selectHeadquarters();
    }, [])

    const changeHeadquarters = (e) => {
        e.preventDefault();

        const dataForm = new FormData(form.current)
        const data = getFormData(dataForm)
        const dataParse = JSON.parse(data);

        const result = headquarters.find(e => e.id == dataParse.headquarters);

        setValueToLocalStorage('headquartersId', dataParse.headquarters);
        setValueToLocalStorage('headquartersName', result.name);
    };

    const getFormData = (dataForm) => {
        const object = {};
        dataForm.forEach(function (value, key) {
            object[key] = value;
        });
        const res = JSON.stringify(object);
        return res
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            email: e.target.email.value,
            password: e.target.password.value,
            headquartersId: e.target.headquarters.value,
        }
        console.log(data);
        //validateUser(data.email, data.password);

        validateLogin(data)
            .then(async (res) => {
                const result = jwt_decode(res.token);
                if (result) {
                    //setValueToLocalStorage('prueba', true);
                    setValueToLocalStorage('token', res.token);
                    const users = await getUsers();

                    const user = users.filter(function (item) {
                        return item.id == result.sub;
                    });

                    if (user.length == 0) {
                        setValueToLocalStorage('userFullName', 'Master');
                        setValueToLocalStorage('firstName', 'Master');
                        setValueToLocalStorage('userId', 0);
                        setValueToLocalStorage('role', result.role);
                    } else {
                        setValueToLocalStorage('userFullName', user[0].firstName + ' ' + user[0].firstLastName);
                        setValueToLocalStorage('firstName', user[0].firstName);
                        setValueToLocalStorage('userId', user[0].id);
                        setValueToLocalStorage('role', result.role);
                    }                    
                    window.location.href = "/dashboard"
                }

            })
            .catch((err) => {
                console.log('error', err);
            });
    }

	return (
        <div className="content-login">
            <img src={require('../../../img/logo-servicent.png')} alt="Logo" className="logo-login" />
            <div className="row px-3 content-form text-center">
                <h1>Iniciar sesión</h1>
                <p>Con el software Senter de Servicent mantener tus procesos al día sí es posible. Para comenzar a trabajar debes inicia sesión.</p>
                <form onSubmit={handleSubmit} noValidate ref={form} className="login-form admin__forms">
                    <div className="form-group">
                        <label>Usuario</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            // value={email}
                            className="form-control"
                            required={true}
                            autoFocus={true}
                            placeholder="Correo electrónico o cédula"
                            // onChange={onChangeField}
                        />
                    </div>

                    <div className="form-group">
                        <label>Contraseña</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            // value={password}
                            className="form-control"
                            placeholder="Contraseña"
                            autoComplete="off"
                            // onChange={onChangeField}
                            required={true}
                        />
                    </div>

                    <div className="form-group">
                        <label>Sede</label>
                        <select
                            name="headquarters"
                            id="headquarters"
                            className="form-select"
                            placeholder="Sede"
                            onChange={changeHeadquarters}
                            defaultValue="0"
                            required={true}>
                            <option disabled value="0">Seleccione una sede...</option>
                            {headquarters.map((headquarter, index) => (
                                <option key={headquarter.id} value={headquarter.id}>{headquarter.name} - {headquarter.local}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-button">
                        {loggingIn ? (
                            <button type="button" disabled className="btn btn-block px-5">
                                <i className="fal fa-spinner fa-spin"></i>
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-block px-5">Ingresar</button>
                        )}
                    </div>
                </form>
            </div>
            <div className="row text-center mt-4 login-footer">
                <p className="mb-0">Todos los derechos reservados a Senter Servicent. Política de privacidad y Términos y condiciones</p>
                <div className="powered mt-4">
                    <span>Powered by</span>
                    <img src="https://webkend.co/wp-content/uploads/2021/01/logo-webkend.svg" alt="Logo Webkend" className="logo-webkend" />
                </div>
            </div>
        </div>
	);
};

export default LoginForm;