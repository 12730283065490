import React from 'react';
import PropTypes from 'prop-types';
import {
    BrowserRouter as Router,
    Routes,
    Route
  } from "react-router-dom";
// import { LoginForm } from './LoginForm';
// import LoginHeader from './LoginHeader';
// import LoginFooter from './LoginFooter';
import LoginForm from './LoginForm';
import { homeRoute } from '../../../routers/routes';

const Login = ({ email, password, handleSubmit, onChangeField }) => {

    return (
        <div className="auth__main">
            <div className="container-flex align-items-center justify-content-center h-100">
                <div className="col-4 px-3">
                    <LoginForm />
                </div>
                <div className="col-8">
                    <img src="https://img.freepik.com/foto-gratis/colegas-dando-golpe-puno_53876-64857.jpg?w=1800&t=st=1666402103~exp=1666402703~hmac=fb7049d14a6dbd62127ae3fda66b69c991e07e3520c13f54f3465a24fa140740" className="img-back" alt="Reunión de equipo" />
                </div>
            </div>
        </div>
	);
};

Login.propTypes = {
	// handleSubmit: PropTypes.func.isRequired,
	// onChangeField: PropTypes.func.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
};

export default Login;